import { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import { useTheme } from "../../../../context/ThemeContext";
import { Badge, Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useNotificationsService } from "../../../../context/firebase/NotificationsContext";
import { useAuthService } from "../../../../context/firebase/AuthContext";
import usePremiumUser from "../../../../global/hooks/usePremiumUser";
import { baseUrl } from "../../../../global/utils";

const HomeHeader = () => {
  const [showHeader, setShowHeader] = useState(true);
  const { notifications } = useNotificationsService();
  const { user } = useAuthService();
  const { isPremiumUser } = usePremiumUser();
  const { mode } = useTheme();
  const navigate = useNavigate();
  let lastScrollY = window.scrollY;

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  const handleScroll = () => {
    if (window.scrollY > lastScrollY) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }
    lastScrollY = window.scrollY;
  };

  const handleClickNotifications = () => {
    navigate("notifications");
  };

  const handleClickChat = () => {
    if (isPremiumUser(user)) {
      navigate("chat");
    } else {
      navigate("/root/premium");
    }
  };

  const unreadCount = notifications?.filter(
    (notification) => !notification.isRead
  ).length;

  const handleGo = async () => {
    let imageUrl =
      "https://firebasestorage.googleapis.com/v0/b/poopmania-prod.appspot.com/o/poops%2Fconorpangburn17%40gmail.com%2FType%206%2Fee8eaf5b-96ba-4a90-a894-cdfa74febc30?alt=media&token=afffa355-afac-4579-8e33-49dab72ffc0a";
    const response = await fetch(`${baseUrl}/imageToBase64`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ imageUrl }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    const url =
      "https://us-central1-poopmania-prod.cloudfunctions.net/api/analyzeImage"; // Replace with your Cloud Function URL
    const apiKey =
      "pm-6EIrupZuZRKWq2Cd9vvHiv8LwOdeHxQ0uovKkDDpXRi7deJ2PumQMK8MLUgbd"; // Replace with your actual API key
    //pm-6EIrupZuZRKWq2Cd9vvHiv8LwOdeHxQ0uovKkDDpXRi7deJ2PumQMK8MLUgbd
    const payload = {
      base64Image: data.base64,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const result = await response.json();
      console.log("Analysis Result:", result);
      return result;
    } catch (error) {
      console.error("Error calling analyzeImage API:", error);
      //@ts-ignore
      alert(`Failed to analyze image: ${error.message}`);
    }
  };

  return (
    <AppBar
      position="fixed"
      sx={{ bgcolor: mode === "dark" ? "#333" : "#fff" }}
      style={{
        top: showHeader ? 0 : "-64px",
        transition: "top 0.3s",
      }}
    >
      <Toolbar>
        <Stack
          direction={"row"}
          alignItems={"center"}
          flex={1}
          justifyContent={"space-between"}
        >
          <Typography variant="h5">For you</Typography>
          {/* <Button onClick={handleGo}>GO</Button> */}
          <Stack direction={"row"} spacing={1}>
            <IconButton size="small" onClick={handleClickChat}>
              <Badge badgeContent={"New"} color="success">
                <ChatOutlinedIcon />
              </Badge>
            </IconButton>
            <IconButton size="small" onClick={handleClickNotifications}>
              {unreadCount && unreadCount > 0 ? (
                <Badge
                  badgeContent={unreadCount > 9 ? "9+" : unreadCount}
                  color="error"
                >
                  <NotificationsOutlinedIcon />
                </Badge>
              ) : (
                <NotificationsOutlinedIcon />
              )}
            </IconButton>
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default HomeHeader;
